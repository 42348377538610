import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "progaming-co-ltd"
    }}>{`ProGaming Co. Ltd.`}</h1>
    <hr></hr>
    <p>{`We believe each business section supports the others. As we work for hire, we learn what people really
need then we pass on that knowledge to our students. As we teach, we turn some of our students into
professional developers which some of them, later, become a part of our team.`}</p>
    <p>{`It is our philosophy that we need to help improving the game industry at any chance we are able to.`}</p>
    <p><img alt="team" src={require("./public/images/progaming.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      